import React, { Component } from 'react';
import _ from 'lodash';
import { HotKey } from "./";

// Serves as an importable hotkey component that can be fed into a global component 
// that couldn't otherwise import the local hotkey instance.

export const HotKeyProxy = ( shortcut, boundTo, callbackFunction ) => {
	// console.log("Binding using depricated hotkey method.")
	// console.trace();
	return (
		<HotKey
	 		shortcut={ shortcut }
	 		boundTo={ boundTo }
	 		callback={ callbackFunction }
	 	/> 
	)

}

