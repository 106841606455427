import React, { Component, useEffect, useState } from 'react';

// This component is duplicated becuase it uses hotkeys and alert contexts which are local to each project

export const RemoteAlert = (props) => {

	const { alert } = props;

	const [ showAlert, setShowAlert ] = useState(false);
	const [ message, setMessage ]     = useState(null);
	const [ modalType, setModalType]  = useState('notice')
	const [ type, setType ]           = useState(null);
	const [ details, setDetails ]     = useState(null);

	useEffect(() => {

		function showMessage(e){
			if( e.detail.modalType ){
				setModalType( e.detail.modalType )
			}
			setDetails( e.detail );
			setMessage( e.detail.message );
		}

		document.addEventListener('open-remote-alert', showMessage)
	  return () => {
	  	// cleanup
	  	document.removeEventListener('open-remote-alert', showMessage);
	  };
	}, []);


	useEffect(() => {
		// If we've set a message string 
		if( message !== null ){
			// Trigger modal to be visible by rendering the "open modal" function.
			setShowAlert( true );
			return
		}
		// Else reset the modal by returning null. This lets it be called and recalled as needed.
		setShowAlert( false )
	}, [message])


	return showAlert ? (
		<>
			{
				alert.openModal({
					header: message,
					type: modalType,
					onConfirm: () => {
						if( details.onConfirmFunction ){
							details.onConfirmFunction()
						}
						setMessage(null);
						setDetails(null);
						setModalType('notice');
					},
					onDeny: () => {
						if( details.onDenyFunction ){
							details.onDenyFunction()
						}

						setMessage(null);
						setDetails(null);
						setModalType('notice');
					},
					onClose: () => {
						setMessage(null);
						setDetails(null);
						setModalType('notice');
					},
				})
			}
		</>
	) : ( null )
	
};